import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"

import HeadingBanner from "@images/hero-bg5.jpeg"

const ThankYouPage = () => (
	<Layout>
		<SEO title="Thank you!" />
		<div
			id="hero-banner"
			style={{
				backgroundImage: `url(${HeadingBanner})`,
				backgroundPosition: `bottom`,
			}}
		>
			<div className="hero-text">
				<div className="container">
					<div className="row justify-content-between align-items-center text-white text-center">
						<div className="col-12">
							<h1>Thank you!</h1>
							<p>
								We have received your message and will get back to you shortly.
							</p>
							<Link to="/" className="hero-cta">
								Back to Home
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default ThankYouPage
